<template>
  <div class="content-wrapper">
    <div class="employees main-content">

      <div class="page-header">
        <h1>Attendance Overview</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Reference ID" @search="searchRecord" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Staff ID">
                <el-input v-model="searchForm.employee_id"></el-input>
              </el-form-item>
              <el-form-item label="Staff Name">
                <el-input v-model="searchForm.employee_name"></el-input>
              </el-form-item>
              <el-form-item label="Position">
                <el-input v-model="searchForm.position"></el-input>
              </el-form-item>
              <el-form-item label="Department">
                <el-input v-model="searchForm.department"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>
      </div>

      <div class="employee-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Reference ID</th>
              <th scope="col">Staff ID</th>
              <th scope="col">Staff Name</th>
              <th scope="col">Position</th>
              <th scope="col">Department</th>
              <th scope="col">Date</th>
              <th scope="col">Start Time</th>
              <th scope="col">End Time</th>
              <th scope="col">Clock In Status</th>
              <th scope="col">Clock Out Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in records">
              <th scope="row">{{record.reference_id}}</th>
              <td>{{record.employee_id}}</td>
              <td>{{record.employee_name}}</td>
              <td>{{record.position}}</td>
              <td>{{record.department}}</td>
              <td v-if="record.date">{{formatDateTime(record.date, 'YYYY/MM/DD')}}</td>
              <td v-else></td>
              <td v-if="record.start_time">{{formatDateTime(record.start_time, 'H:mm:ss')}}</td>
              <td v-else></td>
              <td v-if="record.end_time">{{formatDateTime(record.end_time, 'H:mm:ss')}}</td>
              <td v-else></td>
              <td>{{ getStatusText(record.clock_in_status) }}</td>
              <td>{{ getStatusText(record.clock_out_status) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'AttendanceOverview',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        employee_id: '',
        employee_name: '',
        position: '',
        department: '',
      },
      originRecords: [],
      records: [],
    }
  },
  mounted(){
    this.loadAllAttendance();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    searchRecord(textSearchValue){
      this.searchForm['reference_id'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newRecords = Common.filterItems(searchFilter, this.originRecords);
      this.records = newRecords;
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.records = this.originRecords;
    },
    getStatusText(status){
      const statusList = {
        'at_work': 'Attended',
        'clock_out': 'Clock out',
        'leave_early': 'Leave Early',
        'late': 'Late',
        'ot': 'OT',
        'normal': 'Clock Out',
        'absenteeism': 'Absenteeism',
        'not_attended': 'Not Attended',
      };
      const statusText = statusList[status];
      return statusText;
    },
    async loadAllAttendance(){
      const loginInfo = Common.getLoginInfo();
      try{
        const attendanceRecords = await Hr.loadAllAttendance(this.apiUrl, loginInfo);
        this.originRecords = attendanceRecords;
        this.records = attendanceRecords;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.employee-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
